import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component: () => import('@/Layout/index.vue'),
    redirect: '/home',
    children: [
      {
        path: "home",
        component: () => import("@/views/home.vue"),
      },
      {
        path: "about",
        component: () => import("@/views/about.vue"),
      },
      {
        path: "cooperate",
        component: () => import("@/views/cooperate.vue"),
      },
      {
        path: "news",
        component: () => import("@/views/news.vue"),
      },
      {
        path: "join",
        component: () => import("@/views/join.vue"),
      }
    ]
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/privacy.vue'),
  }
]

const router = new VueRouter({
  routes,
  mode: 'hash'
})

export default router
